<template>
    <div class="box">
        <!-- <Title>发展历程</Title> -->
        <div class=" wow slideInLeft">
            <Img class="img" :srcList='src' :src="require('@/assets/Goin/plan/1.png')"/>
        </div>
        <Pgp class="wow slideInRight">历思起步于司法鉴定行业，经过十多年发展，逐步建立起一个声誉良好的司法鉴定品牌；并以司法鉴定服务为核心，进入智慧装备、价值评估等多个领域。今后，历思科技产研体系将持续创新，推动生物技术、信息技术、刑事技术等当代科技在物证与鉴定领域的融合和创新，并以此获得竞争力与发展机遇。
</Pgp>
    </div>
</template>
<script>
import Img from "@/components/ImagePreview"
import srcPng from "@/assets/Goin/plan/1.png"
export default {
    components:{
        Img,
    },
    data(){
        return {
            src:[srcPng]
        }
    },
}
</script>
<style scoped>
.img{
    width: 100%;
    max-width: 1600px;
    margin: 2rem auto;
}
.title{
    color: #284c91;
}
.box{
    margin:0 auto;
    width:80vw;
    max-width:1600px;
}
/* @media screen and(min-width:768px){

} */
</style>